<template>
  <NavComponent :showSearch="false" />
  <section id="courses">
    <div class="colored_banner">
      <h1 v-if="selectedLang.lang">{{ selectedLang.name }}</h1>
      <h1 v-else>Language</h1>
    </div>
    <div class="container">
      

      <div class="row">
        <div class="col-12 col-xs-12" id="courses_container">
          <div class="container-fluid">
            <div class="row">
              <!-- modules section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Modules</h2>
                <div v-if="!(modules && modules.length)">
                  <p>No modules to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <CourseCell
                    v-for="(obj, index) in modules"
                    :key="index"
                    :course="obj"
                  />
                </div>
              </div>

              <!-- courses section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Courses</h2>
                <div v-if="!(courses && courses.length)">
                  <p>No courses to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <CourseCell
                    v-for="(obj, index) in courses"
                    :key="index"
                    :course="obj"
                    :type="'course'"
                  />
                </div>
              </div>

              <!-- videos section -->
              <div class="col-md-12 col-lg-12 col-xs-12 search_section">
                <h2>Videos</h2>
                <div v-if="!(videos && videos.length)">
                  <p>No videos to show</p>
                </div>
                <div
                  v-else
                  class="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-5"
                >
                  <VideoCell
                    v-for="(obj, index) in videos"
                    :key="index"
                    :video="obj"
                  />
                </div>
              </div>
            </div>
          </div>

          <Loader :isLoading="isLoading" />
        </div>
      </div>
    </div>
  </section>
  <div id="line"></div>
  <FooterComponent />
</template>
<script>
import { watch, watchEffect, ref } from "vue";
//component
import NavComponent from "@/views/components/Nav";
import FooterComponent from "@/views/components/Footer";

import CourseCell from "@/views/components/CourseCell";
import VideoCell from "@/views/components/VideoCell";
// composables
import GetAllByLang from "@/composables/GetAllByLang";
import GetLanguages from "@/composables/GetLanguages";
export default {
  name: "LangResultView",
  components: {
    NavComponent,
    FooterComponent,
    CourseCell,
    VideoCell,
  },
  data() {
    return {
      loadedData: ref([]),
      data_error: null,
      isLoading: false,
      loadedLanguages: [],
      lang_error: null,
      selectedLang: { name: "Results", lang: null },
    };
  },
  mounted() {
    this.load_all_languages()
    const langParam = this.$route.query.key;
    if (langParam) {
      this.selectedLang.lang = langParam
      this.fetchDataResults(langParam);
    }
    this.isLoading = true;
  },
  methods: {
    fetchDataResults(lang) {
      const { loaded_data, data_error, get_data } = GetAllByLang();
      // todo test with updated API that return category parent
      watch([loaded_data, data_error], () => {
        if (loaded_data.value) {
          this.loadedData.value = loaded_data.value;
          this.data_error = null;
          this.isLoading = false;
        }
        if (!this.isRefEmpty(data_error)) {
          this.data_error = data_error.value;
          this.isLoading = false;
        }
      });
      get_data(lang);
    },

    load_all_languages() {
      const { loadedLanguages, lang_error, loadLanguages } = GetLanguages();
      watchEffect(() => {
        // Load languages
        if (!this.isRefEmpty(loadedLanguages)) {
          this.loadedLanguages = loadedLanguages.value;
          console.log('language loaded')
          this.updateLangName(this.loadedLanguages);
          this.lang_error = null;
        }
        if (!this.isRefEmpty(lang_error)) {
          this.lang_error = lang_error.value;
        }
      });
      loadLanguages();
    },
    updateLangName() {
      let foundLanguage = this.loadedLanguages.find(
        (lang) => lang.shortname == this.selectedLang.lang
      );
      if (foundLanguage) {
        this.selectedLang.name = foundLanguage.name; 
        console.log(this.selectedLang)
      }
    },
  },
  computed: {
    courses() {
      if (this.loadedData.value) {
        return this.loadedData.value.courses;
      } else {
        return [];
      }
    },
    modules() {
      if (this.loadedData.value) {
        return this.loadedData.value.modules;
      } else {
        return [];
      }
    },
    videos() {
      if (this.loadedData.value) {
        return this.loadedData.value.videos;
      } else {
        return [];
      }
    },
  },
};
</script>

<style scoped >
.search_section {
  margin-bottom: 60px;
}
.search_section h2 {
  margin-bottom: 10px;
}
</style>